.file-upload-container {
	border: 1px lightgrey dashed;
	padding: 5px;
	background: #f6f9ff;
	text-align: center;
}
.file-upload-container label {
	text-transform: none !important;
}

.main-title {
	font-weight: 500 !important;
}

.sub-title {
	color: grey;
	font-size: small;
}

.standard-spacing {
	margin: 10px !important;
}

.standard-spacing-horizontal {
	margin-left: 10px;
	margin-right: 10px;
}

.standard-spacing-vertical {
	margin-top: 10px;
	margin-bottom: 10px;
}

.error {
	color: red;
}

.menu-wrapper {
	height: 200px;
	width: 250px;
	background-color: rgba(255, 255, 255, 1);
	margin-left: -450px;
	margin-top: -10px;
	opacity: 0;
	-webkit-box-shadow: -10px 10px 6px -8px rgba(161, 136, 119, 0.7);
	transition: all 0.3s ease-in-out;
	border-radius: 2px;
	padding: 0 auto;
	border-right: 1px solid rgba(0, 0, 0, 0.2);
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	position: absolute;
	right: 0;
}

.menu-open {
	margin-left: 0px;
	opacity: 1;
}

.menu-toggle {
	cursor: pointer;
	position: absolute;
	z-index: 1000;
	top: 0;
	right: 0;
	margin: 20px;
	padding: 0px;
	height: 50px;
	width: 50px;
	font-size: 20px;
	color: rgba(0, 0, 0, 0.5);
	font-weight: bold;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.8);
	transform: rotate(0deg);
	transition: all 0.2s ease-in;
	border: 0 none;
}

.menu-toggle:hover {
	cursor: pointer;
	transform: scale(1.1) rotate(0deg);
	-webkit-box-shadow: 10px 2px 8px -8px rgba(179, 164, 139, 0.6);
}

.menu-toggle:focus {
	cursor: pointer;
	outline: 0 none;
}

.close-button {
	cursor: pointer;
	transform: rotate(45deg);
	background-color: rgba(109, 163, 140, 1);
	-webkit-box-shadow: 10px 2px 8px -8px rgba(179, 164, 139, 0.6);
}

.close-button:hover {
	transform: scale(1.1) rotate(45deg);
}

.MuiButton-root,
.MuiToggleButton-root,
.MuiTab-root {
	text-transform: none !important;
}

.no-padding-tab-container .MuiBox-root {
	padding: unset !important;
	margin-top: 10px;
}

.fixTabPanel {
	padding-top: '50px';
}

#tabs4 {
	position: fixed;
	z-index: 999;
}

#companyProfileTab {
	margin-top: 0px;
}

#companyProfileTab .MuiBox-root {
	padding-left: unset;
	padding-right: unset;
}

.DraftEditor-root {
	height: 100px !important;
	padding-left: 5px;
	line-height: normal;
}

/*.rdw-editor-wrapper{
  border : 1px lightgrey solid !important;

  
}
.rdw-editor-main{
}


@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

*/

.mui-file-input > .MuiInputBase-root > label {
	width: 100% !important;
}

.mui-file-input > .MuiInputBase-root > label > span {
	width: 100% !important;
}

.salesplay-title {
	font-size: 16px;
	font-weight: 700;
	//padding-left: 3px !important;
}

.MuiDataGrid-columnHeaderTitle {
	font-size: 16px !important;
	font-weight: 700 !important;
}

.salesplay-left-main-title {
	font-size: 18px !important;
	font-weight: 700 !important;
	color: white;
	cursor: pointer !important;
}
.salesplay-left-title {
	font-size: 16px !important;
	font-weight: 700 !important;
	color: white;
	cursor: pointer !important;
}

.salesplay-form .MuiFormControl-root {
	background: white;
	margin-left: unset !important;
}

.MuiDataGrid-cell {
	border: none !important;
	outline: none !important;
}

.MuiDataGrid-cell {
	border: none !important;
	outline: none !important;
}

.MuiDataGrid-cell:has(.salesplay-item-container) {
	padding-left: unset !important;
}

.overflow-text {
	text-overflow: ellipsis;

	/* Required for text-overflow to do anything */
	/* white-space: nowrap; */
	overflow: hidden;
}

.record-lookup {
	font-size: 14px !important;
	font-weight: 700 !important;
}
.hover-pointer:hover {
	cursor: pointer !important;
}
.cursor-pointer {
	cursor: pointer !important;
}

.MuiDataGrid-row {
	border: none !important;
}
.MuiDataGrid-row:hover {
	background: none !important;
}
.admin-accordion .MuiAccordion-root {
	background: #10273a !important;
	color: white !important;
	cursor: pointer !important;
	/* max-width: 200px !important; */
}

.accordion-no-horizontal-margin .MuiAccordion-root {
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.accordion-expand-no-margin .MuiAccordion-root.Mui-expanded {
	margin: 0px !important;
}
/* 
.MuiAccordionSummary-content{
  width: 200px !important;
} */

.react-rte-editor {
	font-family: Roboto, Helvetica, Arial, sans-serif !important;
	min-height: 437px !important;
}
.popover_class:after {
	content: '';
	position: absolute;
	right: 4px;
	top: -5px;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 0 8px 8px 8px;
	border-color: transparent transparent green transparent;
	z-index: 9998;
}

.salesPlaySearch input {
	cursor: pointer !important;
}

.salesPlaySearchContainer {
	background: #f5f5f5 !important;
}

.MuiDataGrid-virtualScroller {
	min-height: 200px !important;
}

.public-DraftEditor-content {
	min-height: 360px !important;
}
.headingSpaceTop {
	padding-top: 16px;
}
.headingSpaceBottom {
	padding-bottom: 16px;
}

.no-expand-enlarging {
	.MuiAccordionSummary-root .Mui-expanded {
		min-height: unset !important;
	}
	.MuiAccordionSummary-content.Mui-expanded {
		margin: unset !important;
	}
	.MuiCollapse-root .MuiCollapse-vertical {
		margin-top: -10px;
	}
}

.reset-p p {
	margin: unset;
}

.flex-full-container {
	display: flex;
	flex-grow: 1;
	flex-shrink: 1;
}

.flex-col-full-container {
	flex-direction: column;
	@extend .flex-full-container;
}

.sharepoint-file-picker-container {
	padding: 16px;
	border: 1px solid rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	overflow: hidden;
	height: calc(100vh - 180px);

	@extend .flex-col-full-container;

	mgt-file-list {
		overflow-y: auto;
		@extend .flex-col-full-container;

		[data-generated='template'] {
			.sharepoint-files-row {
				border-bottom: 0.5px solid lightgray;
				cursor: pointer;
				&:hover {
					background: #f7f7f7;
				}
			}
		}
	}
}

.line-clamp-4 {
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
}

.line-clamp-4-container {
	-webkit-line-clamp: 4;
}

.uppy-Provider-authIcon img {
	height: 75px;
}
.uppy-Provider-breadcrumbsIcon img {
	fill: #525252;
	height: 25px;
}

.search-result-dialog {
	> .MuiDialog-container {
		> .MuiPaper-root {
			width: 100%;
			max-width: 100%;
			margin-left: 10px;
			margin-right: 10px;
			background: #e5e8ec !important;
		}
	}
}
