@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

#tooltip {
	background: #222;
	color: white;
	font-weight: bold;
	padding: 5px;
	border-radius: 4px;
	font-size: 90%;
	pointer-events: none;
}

html,
body {
	width: 100% !important;
	min-width: 100% !important;
	height: 100% !important;
	min-height: 100% !important;
	font-family: 'Open Sans', sans-serif;
}

::-webkit-scrollbar {
	width: 5px;
	height: 8px;
}

::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	border-radius: 10px;
}

::-webkit-scrollbar-track {
}

.root {
	width: 100% !important;
	min-width: 100% !important;
	height: 100% !important;
	min-height: 100% !important;
}

.category-marker {
	background-color: #e4bc0d;
}

.subcategory-marker {
	background-color: #e7dcaa;
}

.feature-marker {
	background-color: #3592dd;
}

.feature-detail-marker {
	background-color: #b5d8f5;
}

.table-marker {
	background-color: #b5d8f5;
}

.image-marker {
	background: rgb(197 231 241) !important;
	border-radius: 5px;
	padding: 5px;
	line-height: 1.5;
	border: 2px solid #c5e7f1;
}
